import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import useCookieState from "../hooks/useCookieState";
import usePageData from "../hooks/usePageData";
import { PropsType } from "../hooks/propsType";
import Seo from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Insights from "../components/Insights";
import CompareTable from "../components/CompareTable";
import { transformedData } from "../utils/functions";
import "../assets/scss/index.scss";

const Page: React.FC<PropsType> = ({ data, pageContext }) => {
  const [compareList, setCompareList] = useCookieState(
    "compare",
    JSON.stringify([])
  );
  const [openModal, setOpenModal] = React.useState(false);

  const { settings, articles, post, products, curentPage } = usePageData(
    data,
    pageContext
  );
  const comapreProducts = pageContext?.slug?.toLowerCase().split("-vs-");
  const productsSelected = products?.filter((e: any) =>
    comapreProducts?.includes(e?.title?.toLowerCase().replace(/\s+/g, "-"))
  );

  return (
    <div className="tf">
      <Header
        settings={transformedData(settings?.header)}
        settingsFooter={transformedData(settings?.footer)}
        products={products}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Hero
        settings={{
          title: `${productsSelected[0]?.title} VS ${productsSelected[1]?.title}`,
          titleSub: transformedData(settings?.products)
            ?.comparePageSubtitle?.replace("###", productsSelected[0]?.title)
            ?.replace("##", productsSelected[1]?.title),
        }}
        descriptionShort={false}
      />
      <CompareTable
        settings={transformedData(settings?.header)}
        products={productsSelected}
        productsSettings={transformedData(settings?.products)}
      />
      <Insights
        articles={articles?.slice(0, 3)}
        full={false}
        light={true}
        settings={transformedData(settings?.news)}
      />
      <Footer settings={transformedData(settings?.footer)} />
    </div>
  );
};

export const Head: HeadFC<PropsType> = ({ data, pageContext }) => {
  const { products, settings } = usePageData(data, pageContext);
  const comapreProducts = pageContext?.slug?.toLowerCase().split("-vs-");
  const productsSelected = products?.filter((e: any) =>
    comapreProducts?.includes(e?.title?.toLowerCase().replace(/\s+/g, "-"))
  );
  return (
    <Seo
      title={transformedData(settings?.products)
        ?.comparePageMetaTitle?.replace("###", productsSelected[0].title)
        ?.replace("##", productsSelected[1]?.title)}
      description={transformedData(settings?.products)
        ?.comparePageMetDescription?.replace("###", productsSelected[0].title)
        ?.replace("##", productsSelected[1]?.title)}
    />
  );
};

export const query = graphql`
  query {
    allMdx(sort: { frontmatter: { date: DESC } }) {
      nodes {
        ...MdxFields
      }
    }
  }
`;

export default Page;
